@import '../../../app/theme';

.microfrontend {
  width: 100%;
  padding: 32px 44px;
  font-family: 'Ubuntu', sans-serif !important;

  @media (max-width: $mobile) {
    padding: 20px;
  }

  :global {
    .form-input__container, .form__container, .form__container_scroll,
    .search-button__container, .card__list, .search-button__cancel {
      background-color: transparent !important;
    }

    .tm-header-button:not(.head__burger) {
      display: none;
    }

    .tm-pagination__container {
      background-color: $background !important;
    }

    .search-button__cancel:hover {
      background-color: $background !important;
      text-decoration: underline;
    }

    .search-button__search:hover {
      background-color: $primary-active;
    }

    .process-card__info, .tm-column__small_operations {
      background-color: $white;
    }

    .card__elem, .process-card__info, .tm-column__small_operations {
      box-shadow: 0 0 20px rgba(128, 132, 193, .1);
      border: none;
    }

    .tm-pagination .tm-arrow__button {
      padding: 4px 15px;
    }

    .search-button__container {
      height: 44px;
      padding: 5px 0;

      span {
        padding: 0 26px 0 23px;
        display: flex;
        align-items: center;
      }
    }

    .card__metadata {
      display: flex;
      flex-direction: row;

      .card__metadata-item {
        margin-right: 50px;
      }
    }

    .tm-column {
      .tm-header-container {
        margin-bottom: 20px;
        height: auto !important;
      }
    }

    .process-card__info_context {
      display: flex;
      flex-wrap: wrap;

      @media (max-width: $mobile) {
        flex-direction: column;
      }

      .date-time-formatted__container, .paragraph__container, .valueNSI__container {
        width: 25%;
        margin-top: 0 !important;
        
        @media (max-width: $mobile) {
          width: 100%;
        }
      }
    }

    .form__container_scroll .form-input__container:first-child div,
    .filter-container .form-input__container:first-child div {
      top: 42px !important;
    }

    .css-jy4fgo-control, .react-date-picker, .form-input{
      border-style: solid;
      border-color: var(--input-border);
      border-radius: 8px;
    }
    
    .footer-form__container {
      background: transparent;
    }

    .footer-form__container span {
      display: flex;
      height: 34px;
      padding: 0 20px;
      align-items: center;
    }

    .form-field__container {
      width: 100% !important;
    }
  }
}

:root {
  --primary: #{$primary} !important;
  --primary-active: #{$primary-active} !important;
  --pagination-button--color: #{$primary} !important;
  --card-elem--border-color-hover: #{$primary} !important;
  --card-elem--background-hover: #{$primary} !important;
  --card-status-name--background: #{$primary} !important;
  --card-status-name--color-hover: #{$primary} !important;
  --status-in-progress: #{$primary} !important;
  --input-background: #{$white} !important;
  --input-border: #{$border} !important;
  --body-background: #{$background} !important;
  --main-background-color: #{$background} !important;
  --search-button-cancel--background: #{$background} !important;

}


