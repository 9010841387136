$light: #f4f4f6;
$pale-grey: #9fa2b4;

$white: white;
$primary: #6266a7;
$primary-active: darken($primary, 15%);
$text-primary: #50515b;
$text-secondary: #767886;
$text-tertiary: #bebfc8;
$page-background: #ecedf6;
$border: #dadada;
$background: #f5f5f5;

$mobile: 768px;
