@import '/src/app/theme.scss';

.footerContainer {
  background-color: $primary;
  height: 106px;
  max-height: 106px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  
  @media (max-width: $mobile) {
    height: 100%;
    max-height: 255px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 20px;
    justify-content: flex-start;
  }
}

.n3Logo {
  display: flex;
  align-items: center;
  margin-right: 91.1px;
  margin-left: 38px;

  @media (max-width: $mobile) {
    margin-right: 0;
    margin-left: 0;
  }
}

.milaLogo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 120px;

  @media (max-width: $mobile) {
    margin-right: 0;
  }

  svg {
    margin-right: 10px;
  }
}

.rowsWrapper {
  display: flex;
  flex-direction: column;

  a, span {
    color: #ffffff;
    font-family: 'Ubuntu';
    font-weight: 400;
    line-height: 24px;
  }
}

.privatePolicyDesc {
  display: flex;
  align-items: center;
  margin-right: 186px;

  @media (max-width: $mobile) {
    margin-right: 0;
  }

  a, span {
    font-weight: 300;
    white-space: nowrap;
  }
}

.markDesc {
  display: flex;
  align-items: center;
  width: 332px;
  margin-right: 32px;

  @media (max-width: $mobile) {
    margin-right: 0;
  }

  span {
    font-weight: 300;
  }
}
