.App {
  text-align: center;
}

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

body {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.appContainer {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f9f9fc;
}

.ant-checkbox-wrapper,
.ant-checkbox-inner,
.ant-checkbox-wrapper:hover span.ant-checkbox-inner,
span.ant-checkbox-checked::after {
  border-color: #6266a7 !important;
}

.ant-checkbox-checked span.ant-checkbox-inner,
.ant-btn-primary.ant-btn-primary,
.ant-btn-primary.ant-btn-primary:hover {
  border-color: #6266a7;
  background-color: #6266a7 !important;
}

.ant-checkbox-disabled {
  // border: 1px solid rgba(27, 27, 30, 0.12);
  //background-color: #bebfc8 !important;

  span.ant-checkbox-inner {
    background-color: rgba(27, 27, 30, 0.12) !important;
  }
}

.ant-checkbox-wrapper-disabled {
  .ant-checkbox-inner {
    border-color: rgba(27, 27, 30, 0.12) !important;
  }
}

span.ant-checkbox-checked.ant-checkbox-checked.ant-checkbox-checked::after {
  visibility: hidden;
}

.ant-spin-dot-item {
  background-color: #6266a7 !important;
}

.ant-table-cell {
  color: #50515b;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
}

.ant-pagination-item-active {
  background-color: #6266a7 !important;
  border-color: #6266a7 !important;

  a {
    color: #fff !important;
  }
}

.ant-pagination-item-link {
  svg {
    color: #6266a7 !important;
  }
}

.ant-pagination-disabled {
  svg {
    color: #bebfc8 !important;
  }
}

.ant-table-row-level-1 {
  .ant-table-cell-with-append {
    svg {
      display: none;
    }
  }

  button {
    svg {
      display: block !important;
    }
  }
}

.ant-input:hover,
.ant-input:focus,
.ant-select-selector:hover,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper-focused,
.ant-picker:hover,
div.ant-select-selector:hover,
.ant-select-selector:focus {
  border-color: #6266a7 !important;
}

.ant-select-dropdown .ant-select-item-option-selected {
  background-color: rgba(98, 102, 167, 0.08) !important;
}

.ant-upload-wrapper:hover,
.ant-upload-drag,
.ant-upload-drag:hover {
  border-color: transparent !important;
}

.ant-input {
  color: #50515b;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.ant-select-selection-item {
  color: #50515b;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.ant-picker-input,
.ant-picker {
  input {
    color: #50515b !important;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.ant-btn-default {
  border-color: transparent;
}

.ant-btn-default:hover {
  border-color: #6266a7 !important;
}

.ant-input {
  padding-top: 12px;
}

.pageLoader {
  margin-top: 150px;
  display: block;
  min-height: 700px;
}
