@import '/src/app/theme.scss';

.authFormWrapper {
  min-height: 90vh;
  background: rgba(98, 102, 167, 0.12) url('../../assets/AuthBackground.png') no-repeat fixed center center;
  background-size: cover;
}

.technicalInfoWrapper {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}

.technicalInfoContainer {
  width: 422px;
  border-radius: 18px;
  background: #F69D97;
  display: flex;
  gap: 10px;
  color: $white;
  align-items: center;
  padding: 0 10px;
  font-size: 13px;
  min-height: 50px;
}

.technicalInfoText {
  line-height: 18px;
}

.formContainer {
  background-color: $white;
  text-align: center;
  margin: 100px auto;
  border: 1px solid $border;
  width: 442px;
  height: 621px;
  align-self: center;
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile) {
    width: calc(100% - 10px);
  }
}

.formContainerTechnical {
  margin-top: 20px;
}

.SelectNetwork {
  :global {
    .ant-select-selector.ant-select-selector {
      height: 56px;

      .ant-select-selection-item {
        padding-top: 12px;
      }

      .ant-select-selection-placeholder {
        padding-top: 12px;
        font-size: 16px;
      }
    }
  }
}

.formNetworkContainer {
  background-color: $white;
  text-align: center;
  margin: 100px auto;
  border: 1px solid $border;
  width: 642px;
  height: 476px;
  align-self: center;
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile) {
    width: calc(100% - 50px);
    padding: 20px;
  }
}

.logo {
  margin-top: 64px;
  margin-bottom: 36px;
}

.loginButton {
  height: 48px;
  background: $primary;
  width: 100%;
  border-radius: 6px;

  span {
    color: $white;
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
  }
}

.formTitle {
  margin-bottom: 40px;

  span {
    color: $text-primary;
    font-size: 24px;
    letter-spacing: -2%;
    font-style: normal;
    line-height: 20px;

    @media (max-width: $mobile) {
      font-size: 20px;
    }
  }
}

.loginInputWrapper {
  width: 319px;
  margin: 0 auto;
  word-wrap: break-word;

  .ant-input {
    height: 56px;
  }
}

.inputWrapper {
  width: 550px;
  margin: 0 auto;
  word-wrap: break-word;

  .ant-input {
    height: 56px;
  }
}

.ResetPwdLink {
  margin-bottom: 24px;

  button {
    color: $primary !important;
    font-size: 14px;
    font-style: normal;
    line-height: 10px;
    font-weight: 500;
  }
}

.UserAgreementLink {
  margin-top: 48px;
  margin-bottom: 16px;

  a {
    color: $primary !important;
    font-size: 14px;
    font-style: normal;
    line-height: 10px;
    font-weight: 500;
  }
}

.SupportMsgLink {
  a {
    color: $primary !important;
    font-size: 14px;
    font-style: normal;
    line-height: 10px;
    font-weight: 500;
  }
}

.LoginFormInput {
  height: 56px;
  padding-top: 0;
}

.passwordContainer {
  position: relative;
}

.passwordEye {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 20px;
  background: url('../../assets/Eye.svg') no-repeat center center;

  &.Open {
    height: 34px;
    top: 9px;
    background: url('../../assets/EyeClose.svg') no-repeat center center;
  }
}
